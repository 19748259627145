import React from "react"
import { PageLayout } from "../../components/layouts"
import PageHeader from "../../components/pageHeader"
import PageWrapper from "../../components/pageWrapper"
import SEO from "../../components/seo"
import VideoItem from "./VideoItem"
import TextBlock from "../../components/textBlock"
import "./index.scss"
import Blockquote from "../../components/blockquote"
import { VideoPlayer } from "../../components/video"
import {
  ScheduleVisitButton,
  RegisterForWebinarButton,
} from "../../components/buttons"

const VideoPage = props => {
  const { data } = props
  const posts = data.allMarkdownRemark.edges
  return (
    <PageLayout background="#f5f7f9" showBlog={false}>
      <SEO title="Long Term Care Insurance Advice Videos | Corey Rieck | LTCPG" />
      <SEO ogDescription="These long term care videos can help you learn more about the complex long term care options. Corey Rieck and his team focus on your LTC education." />

      <PageHeader
        header="Long Term Care Insurance Advice Videos"
        subheader="Corey Rieck shares his knowledge of the long term care industry and explains how to get the most out of long term care insurance options."
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
              <p>
                I became focused on longterm care planning after managing my parents' finances, as they aged and needed longterm care, I realized there had to be a better way for people to receive the best possible longterm care without forfeiting assets and depleting their bank accounts. Longterm care planning is all I and my organization have done since 2001. I partnered with other professionals, such as financial advisors, attorneys, and insurance agents to bring my sole focus and expertise to their client base. I have a wide network of individuals with subject matter expertise who can potentially help you and your clients with matters beyond longterm care planning, as needed and called upon. I know how to help protect your financial assets and wealth management with the funding strategy for longterm care. I can answer your questions and explain from my personal experience, why you need to know what your options are related to government programs and or your family members in order to provide longterm care and pay related expenses.
              </p>
              <p>
                My team and I find the best solution to fit a person's underwriting and funding needs because we are not tied to any one company or carrier. We also have access to multiple funding options and plans. When a person decides to explore longterm care financial solutions, my team, and I make a complicated process simple and easy to manage for people using our years of experience and a step by step approach. Here are a few of the things that we provide our clients. We can review an existing, personal, or corporate longterm care policy. We can discuss personal options for longterm care funding. We can create a longterm care employee benefit as part of an executive compensation plan. We can assist attorneys, financial advisors and insurance agents with the review of their client's existing longterm care policies, or discuss their funding options for clients who do not currently have a longterm care plan in place.
              </p>
            <RegisterForWebinarButton />
          </div>
        }
        textRight={
          <div>
            <img
              src="/images/corey-rieck-long-term-planning-group.jpg"
              alt="Long Term Care Insurance Advice Videos"
            />
            <Blockquote>
              I'm a business community advocate. I'm an educator at heart. Part of my commitment to the financial services industry includes having trained over 4,000 advisors from San Francisco to Wall Street since 2003 on how to properly position longterm care for their clients, contacts and friends through the certified and longterm care or CLTC masterclass program. I'm well connected with many professionals in the Metro Atlanta area, and often introduce colleagues when asked to make referrals.
            </Blockquote>
          </div>
        }
      />
      <PageWrapper>
        <VideoPlayer youtubeId='-pNRasUS56U' />
      </PageWrapper>
      <div className="video-items">
        {posts.map(({ node }) => (
          <VideoItem
            date={new Date(node.frontmatter.date).toLocaleDateString()}
            slug={node.fields.slug}
            title={node.frontmatter.title}
            titletag={node.frontmatter.titletag}
            image={node.frontmatter.previewImage}
            description={node.frontmatter.description}
            posttype={node.frontmatter.posttype}
          />
        ))}
      </div>
    </PageLayout>
  )
}

export default VideoPage

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      filter: { frontmatter: { posttype: { eq: "video" } } }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      edges {
        node {
          id
          frontmatter {
            description
            date
            title
            titletag
            previewImage
            posttype
          }
          fields {
            slug
          }
        }
      }
    }
  }
`
