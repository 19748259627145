import React, { Component } from "react"
import { Link } from "gatsby"
import { BsCameraVideoFill } from "react-icons/bs";

import "./item.scss"

export default function VideoItem(props) {
  return (
    <Link to={`${props.slug}`} className="video-item">
      <span className="media-type">
        <BsCameraVideoFill /> {props.posttype}
      </span>
      <img src={`/images/videos/${props.image}`} />
      <div>
        <h2>{props.title}</h2>
        <p className="description">{props.description}</p>
      </div>
    </Link>
  )
}
